<template>
  <div class="document main-block">
    <form @submit.prevent="$emit('btnSubmit')">
      <div class="main-block__head">
        <div class="flex">
          <h1 class="main-block__title">Документы</h1>
        </div>
        <!--        <button class="main-block__btn default-btn" type="submit">Сохранить</button>-->
      </div>
      <div class="document__block">
        <ImgBlock
          :data-doc-list="dataDocList"
          :data-img="dataImg"
          type-of="doc"
          :size="'small'"
          @images="onChangeImage('doc', $event)"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    ImgBlock: () => ({
      component: import("../../../components/add/addImage"),
    }),
  },
  props: {
    transportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      doc: [],
      dataDocList: [],
      dataImg: {
        title: "Перетащите файлы сюда",
        btn: "Выбрать файл",
        type: "/svg",
      },
    };
  },
  mounted() {
    if (this.transportData && this.$route.query.id) {
      this.dataDocList = this.transportData?.dataDocList;
    }
  },
  created() {
    if (this.transportData && this.$route.query.id) {
      this.dataDocList = this.transportData?.dataDocList;
    }
  },
  methods: {
    async onChangeImage(key, params) {
      this.$emit("onInputHandlerImage", {
        key,
        params,
      });
    },
  },
  computed: {
    ...mapState(["addTransport", "imagesAdd"]),
  },
};
</script>

<style scoped>
.document__block {
  /* height: 240px; */
  margin-top: 15px;
}
.document {
  min-height: 100px;
}
</style>
